/**
 *
 * kfeducation Video Popups
 * @author     Joseph John
 * @date       03/23/2010
 * @updated    08/18/2011
 */

$(function () {
    // Important to keep the dialog creation in the click function - allows audio to die in IE

    function getShockWaveObj(vid_width, vid_height, video_flv, video_image, video_home) {
        var shockWaveObject = new SWFObject("/flash/player.swf", "homepageflash1", vid_width, vid_height, "6", "#cccccc");
        shockWaveObject.addParam("wmode", "transparent");
        shockWaveObject.addParam("quality", "medium");
        shockWaveObject.addParam("loop", "false");
        shockWaveObject.addParam("allowfullscreen", "true");
        shockWaveObject.addVariable("streamer", "rtmp://cp72569.edgefcs.net/ondemand/");
        shockWaveObject.addVariable("file", video_flv);
        shockWaveObject.addVariable("type", "rtmp");
        shockWaveObject.addVariable("autostart", "false");
        shockWaveObject.addVariable("image", video_image);
        shockWaveObject.write(video_home);
        return shockWaveObject;
    }

    /**
     * Create dialog from video_div, opens it, and positions it
     * @param string video_div id of the div to create the dialog from
     * @param string video_description_div id of the div containing description
     * @param string video_title title of video
     */
    function createVideoDialog(video_div, video_description_div, video_title) {
        $('#' + video_div).dialog({
            autoOpen: false,
            resizable: true,
            modal: true,
            width: '585', // default width - if not specified
            height: '505',
            title: video_title,
            close: function(ev, ui) {
                $('#' + video_description_div).css({"display": "inline"});
                $('#' + video_div).dialog('destroy'); // Removes the Audio from IE on Close - whether pressing ESC or clicking Close :)
            }
        });
        $('#' + video_div).dialog('open');
        $('#' + video_div).dialog('option', 'position', 'center');
    }

    // * * * * * *
    // These are the default settings.  If you need
    // different settings, change them in the particular
    // case statement.  Uncomment the alert below to see
    // which case is called or if one needs to be added.
    // * * * * * *
    var video_div = "video_dialog";// dialog div
    var video_description_div = "video_dialog_description";// description div in dialog div
    var video_home = "home_video";  // video div in dialog div
    var vid_width = '552';// video width
    var vid_height = '445';// video height
    var video_title = 'Instructional Video';// dialog div title
    var video_image = '';

    // Any div with an id=showVideo_...
    $("[id^='showVideo']").on('click', function () {
        var buttonId = $(this).attr("id");
        // Use the div id to calculate the name of hidden field
        //  where the .flv file path is located.
        var video_type = buttonId.substr(10, (buttonId.length-10));
        var video_flv = $("#" + video_type + "Url").val();
        switch (video_type) {
        	case 'StandardLogin':
        		video_title = 'Login Instruction';
        		break;
        	case 'BBY_StandardLogin':
        		video_title = 'Browse Portal Catalog Instruction';
        		break;
        	case 'AddUser_StandardLogin':
        		video_title = 'Create Account Instruction';
        		break;

        }
        switch (video_type) {
            case 'acm_meeting':
                video_title = 'Annual Compliance Meeting (ACM)';
            break;
            case 'StandardLogin':
            case 'BBY_StandardLogin':
            case 'AddUser_StandardLogin':
            case 'PortalUserAssignment':
            case 'EditUser':
            case 'AddUser':
            case 'EnrollmentReport':
            case 'TransactionReport':
            case 'SSPReports':
            case 'FEReports':
            case 'REcampusInstruction':
                video_flv = $("#video_url_" + video_type).val();
                video_div = "help_video_" + video_type;
                video_description_div = "video_description_" + video_type;
                video_home = "video_home_" + video_type;
            break;
            case 'OnlineCourseIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_SearchableFAQs.flv';
                video_image = '/images/Instr_video_images/vid_study_cal.png';
                video_title = 'Online Course';
            break;
            case 'FAQsIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_SearchableFAQs.flv';
                video_image = '/images/Instr_video_images/vid_searchable_faq.png';
                video_title = 'Searchable FAQs';
            break;
            case 'BlogsIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_Exam_TipsBlog.flv';
                video_image = '/images/Instr_video_images/vid_blog.png';
                video_title = 'Exam-tips Blog';
            break;
            case 'StudyCalendarIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_StudyCalendar.flv';
                video_image = '/images/Instr_video_images/vid_qbank_search.png';
                video_title = 'Study Calendar';
            break;
            case 'InstEmailIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_InstructorEmailAccess.flv';
                video_image = '/images/Instr_video_images/vid_qbank_search.png';
                video_title = 'Instructor Email Access';
            break;
            case 'BrwsQustnsIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_BrowseQuestions.flv';
                video_image = '/images/Instr_video_images/vid_browse_questions.png';
                video_title = 'Browse Questions / myNotes';
            break;
            case 'ExmMngmntIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_ExamManagement_ExamReview.flv';
                video_image = '/images/Instr_video_images/vid_exam_review.png';
                video_title = 'Exam Management / Exam Review';
            break;
            case 'SearchIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_SearchVideoScreen.flv';
                video_image = '/images/Instr_video_images/vid_qbank_search.png';
                video_title = 'Search';
            break;
            case 'VidReviewIL':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'OnDemand Online Video Review';
            break;
            case 'StateSyllabusIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_Syllabus.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'State Syllabus';
            break;
            case 'CrssRefIL':
                video_flv = 'flashmedia/kaplan_demos/Insurance/Post_Purchase/IL_CrossReferenceStudyGuide.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'State Exam Outline Cross Reference';
            break;
            case 'ClassNotesIL':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Class Notes';
            break;
            case 'ErrataIL':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Updates / Errata';
            break;
            case 'ContactIL':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Who Do I Contact';
            break;
            case 'CalendarSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Study_Calendar.flv';
                video_image = '/images/Instr_video_images/vid_qbank_search.png';
                video_title = 'Study Calendar';
            break;
            case 'FAQsSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Searchable_FAQs.flv';
                video_image = '/images/Instr_video_images/vid_browse_questions.png';
                video_title = 'Searchable FAQs';
            break;
            case 'BlogSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Exam_Tips_Blog.flv';
                video_image = '/images/Instr_video_images/vid_exam_review.png';
                video_title = 'Exam-tips Blog & Test Alerts';
            break;
            case 'VidLibrarySec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_qbank_search.png';
                video_title = 'Video Library';
            break;
            case 'EmailSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Instructor_Email_Access.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Instructor Email Access';
            break;
            case 'BrwsQustnsSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Browse_Questions.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Browse Questions / myNotes';
            break;
            case 'ExmMngmntSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/SecuritiesPro_QBank_Final.mp4';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Exam Management / Exam Review';
            break;
            case 'SearchSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Search.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Search';
            break;
            case 'ErrataSec':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Errata Reporting';
            break;
            case 'FinalExamsSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Practice_and_Mastery_Exams.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Practice and Mastery Exam';
            break;
            case 'PracticeExamSec':
                video_flv = 'flashmedia/kaplan_demos/Securities/Securities_Practice_Exam.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Practice Exam';
            break;
            case 'OndemandClassSec':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'OnDemand Online Class';
            break;
            case 'WhomToContactSec':
                video_flv = 'flashmedia/kaplan_demos/Securities_Video_Library.flv';
                video_image = '/images/Instr_video_images/vid_video_library.png';
                video_title = 'Who Do I Contact';
            break;
            case 'demo_link_schweser_library':
                video_flv = 'flashmedia/kaplan_demos/CFA/CFA_LibraryDemo.flv';
                video_image = '';
                video_title = 'Schweser Library Sample';
            break;
            case 'demo_link_16_week':
                video_flv = 'flashmedia/kaplan_demos/CFA/VideoCD.flv';
                video_image = '';
                video_title = 'Video Instruction Sample';
            break;
            case 'demo_link_cfa1':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level1.flv';
                video_image = '';
                video_title = 'Quantitative Methods Demo';
            break;
            case 'demo_link_cfa2':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level2.flv';
                video_image = '';
                video_title = 'Corporate Finance Demo';
            break;
            case 'demo_link_cfa3':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level3.flv';
                video_image = '';
                video_title = 'Execution of Portfolio Decisions Demo';
            break;
            case 'demo_link_mock_exam':
                video_flv = 'flashmedia/kaplan_demos/CFA/L2_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Schweser Online Mock Exam Sample';
            break;
            case 'demo_link_online_mock_demo':
                video_flv = 'flashmedia/kaplan_demos/CFA/L1_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Schweser Online Mock Exam Sample';
            break;
            default:
            break;
        }

        if(video_flv != '' && video_flv != undefined) {
        	var shockWaveObject = getShockWaveObj(vid_width, vid_height,video_flv,video_image, video_home);
        }
        createVideoDialog( video_div, video_description_div, video_title);
        return false;
    });

    /* Demo vids for CFA portal prods -- links are not set up nicely with showVideo_ prepending the id's.
    'demo_link_schweser_library':
    'demo_link_16_week'
    'show_review_video_l1'
    'show_mock_exam_video'
    'display_video_link_l1'
    */
    // Any div with an id="display_video_..."
    $("[id^='display_video']").on('click', function () {
        var buttonId = $(this).attr("id");
        var video_type = buttonId.substr(14, (buttonId.length-14));
        var video_flv = $("#" + video_type + "Url").val();

        switch (video_type) {
            // Demo vids for CFA portal prods
            case 'online_exams_link':
            case 'link_l1':
                // vid of level 1D Mock Exam
                video_flv = 'flashmedia/kaplan_demos/CFA/L1_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Testing Kaplan Schweser Mock Exam Demo';
            break;
            default:
                break;
        }

        var shockWaveObject = getShockWaveObj(vid_width, vid_height,video_flv,video_image, video_home);
        createVideoDialog(video_div, video_description_div, video_title);
        return false;
    });

    // Any div with an id="demo_link_..."
    $("[id^='demo_link']").on('click', function () {
        var buttonId = $(this).attr("id");
        var video_type = buttonId.substr(10, (buttonId.length-10));
        var video_flv = $("#" + video_type + "Url").val();

        switch (video_type) {
            // Demo vids for CFA porta     prods
            case 'schweser_library':
                video_flv = 'flashmedia/kaplan_demos/CFA/CFA_LibraryDemo.flv';
                video_image = '';
                video_title = 'Schweser Library Sample';
            break;
            case '16_week':
                video_flv = 'flashmedia/kaplan_demos/CFA/VideoCD.flv';
                video_image = '';
                video_title = 'Video Instruction Sample';
            break;
            case 'cfa1':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level1.flv';
                video_image = '';
                video_title = 'Quantitative Methods Demo';
            break;
            case 'cfa2':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level2.flv';
                video_image = '';
                video_title = 'Corporate Finance Demo';
            break;
            case 'cfa3':
                video_flv = 'flashmedia/kaplan_demos/CFA/16-Week_Level3.flv';
                video_image = '';
                video_title = 'Execution of Portfolio Decisions Demo';
            break;
            case 'mock_exam':
                video_flv = 'flashmedia/kaplan_demos/CFA/L2_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Schweser Online Mock Exam Sample';
            break;
            case 'online_mock_demo':
                video_flv = 'flashmedia/kaplan_demos/CFA/L1_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Schweser Online Mock Exam Sample';
            break;
            default:
            break;
        }

        var shockWaveObject = getShockWaveObj(vid_width, vid_height,video_flv,video_image, video_home);
        createVideoDialog(video_div, video_description_div, video_title);
        return false;
    });


   // Any div with an id="show_..."
    $("[id^='show_']").on('click', function (){
        var buttonId = $(this).attr("id");
        var video_type = buttonId.substr(5, (buttonId.length-5));
        var video_flv = $("#" + video_type + "Url").val();

        switch (video_type) {
            // Demo vids for CFA portal prods
            case 'review_video_l1':
                video_flv = 'http://videos.schweser.com/CFA/Level1_3Day_Demo.flv';
                video_image = '';
                video_title = '3 Day Seminar Archive Demo';
            break;
            case 'review_video_l2':
                video_flv = 'http://videos.schweser.com/CFA/Level2_3Day_Demo.flv';
                video_image = '';
                video_title = '3 Day Seminar Archive Demo';
            break;
            case 'review_video_l3':
                video_flv = 'http://videos.schweser.com/CFA/Level3_3Day_Demo.flv';
                video_image = '';
                video_title = '3 Day Seminar Archive Demo';
            break;
            case 'mock_exam_video':
                video_flv = 'flashmedia/kaplan_demos/CFA/L1_Mock_Exam_Demo.flv';
                video_image = '';
                video_title = 'Kaplan Schweser Mock Exam Demo';
            break;
            default:
            break;
        }

        var shockWaveObject = getShockWaveObj(vid_width, vid_height,video_flv,video_image, video_home);
        createVideoDialog(video_div, video_description_div, video_title);
        return false;
    });
});
